#api-message-modal {
	.modal-content {
		&::before {
			background-image: url('https://hosono.ai/images/logo/hosonoai-maskottchen.svg');
			background-repeat: no-repeat;
			content: "";
			height: 3rem;
			position: absolute;
			left: 1rem;
			top: -2.7rem;
			width: 3rem;
			z-index: -1;
		}
	}
}