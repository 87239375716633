body, html{
    overflow-x: hidden;
}

body {
    main {
        .c-details span {
            font-weight: 300;
            font-size: 13px
        }

        .icon {
            width: 2rem;
            height: 2rem;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .8rem
        }

        .badge span {
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center
        }

        .progress {
            height: 10px;
            border-radius: 10px
        }

        .text1 {
            font-size: 14px;
            font-weight: 600
        }

        .text2 {
            color: #a5aec0
        }
        .tool-cards {
            transform: scale(1);
            transition: all 300ms ease-in-out 0s;
            &:hover {
                transform: scale(1.05);
                transition: all 300ms ease-in-out 0s;
            }
            small {
                min-height: 36px;
            }
        }
    }
}