body {
	font-family: 'Quicksand', sans-serif;
}

a {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

strong,b {
    font-weight: 500;
}

.h6,
h6 {
	font-size: 1em;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	font-weight: 500!important; //700 is too much
    font-family: 'Omnes', sans-serif;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5,h6 {
    font-size: 1.2rem;
}

// Omnes
@font-face {
    font-family: 'Omnes';
    src: url('/assets/styles/fonts/omnes/Omnes-Bold.woff2') format('woff2'),
        url('/assets/styles/fonts/omnes/Omnes-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Omnes';
    src: url('/assets/styles/fonts/omnes/Omnes-Medium.woff2') format('woff2'),
        url('/assets/styles/fonts/omnes/Omnes-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

// QUICKSAND
@font-face {
    font-family: 'Quicksand';
    src: url('/assets/styles/fonts/quicksand/Quicksand-Light.woff2') format('woff2'),
        url('/assets/styles/fonts/quicksand/Quicksand-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('/assets/styles/fonts/quicksand/Quicksand-Bold.woff2') format('woff2'),
        url('/assets/styles/fonts/quicksand/Quicksand-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('/assets/styles/fonts/quicksand/Quicksand-Medium.woff2') format('woff2'),
        url('/assets/styles/fonts/quicksand/Quicksand-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('/assets/styles/fonts/quicksand/Quicksand-Regular.woff2') format('woff2'),
        url('/assets/styles/fonts/quicksand/Quicksand-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('/assets/styles/fonts/quicksand/Quicksand-SemiBold.woff2') format('woff2'),
        url('/assets/styles/fonts/quicksand/Quicksand-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

