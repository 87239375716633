.youtube {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* Verhältnis von Breite zu Höhe des Videos (9:16=56.25%) */
}

.youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}