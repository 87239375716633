@charset "UTF-8";
:root {
  --bs-gold: #ffd700;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary: #7436bf;
  --bs-primary-hover: #B25EFF;
  --bs-secondary: var(--bs-warning);
  --bs-secondary-hover: #FFD845;
  --bs-primary-rgb: 116, 54, 191;
  --bs-secondary-rgb: 255, 193, 7;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(-45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-heading-color: ;
  --bs-link-color: var(--bs-primary-hover);
  --bs-link-hover-color: var(--bs-primary-hover);
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --translate-smooth: all 300ms ease-in-out;
  --box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 0px,
                rgba(0, 0, 0, 0.05) 0px 12px 24px -4px;
}

.btn {
  --bs-btn-focus-box-shadow: none;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary-hover);
  --bs-btn-hover-border-color: var(--bs-primary-hover);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #FFA2C3;
  --bs-btn-disabled-border-color: #FFA2C3;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-secondary-hover);
  --bs-btn-hover-border-color: var(--bs-secondary-hover);
  --bs-btn-focus-shadow-rgb: 130,138,145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-secondary);
  --bs-btn-disabled-border-color: var(--bs-secondary);
}

.list-group {
  --bs-list-group-active-bg: var(--bs-primary);
  --bs-list-group-active-border-color: var(--bs-primary);
  --bs-list-group-item-padding-y: 0.4rem;
}

.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="var%28--bs-body-color%29"%3e%3cpath fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3e%3c/svg%3e);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23ffffff"%3e%3cpath fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: var(--bs-primary);
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: white;
  --bs-accordion-active-bg: var(--bs-primary);
}

.table-primary {
  --bs-table-color: #fff;
  --bs-table-bg: var(--bs-primary);
  --bs-table-border-color: var(--bs-primary);
  --bs-table-striped-bg: var(--bs-primary);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: var(--bs-primary);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: var(--bs-primary);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #E2FFE4;
  --bs-alert-border-color: #8EF689;
}

.list-group-item,
.card {
  border: none;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1) !important;
}

.form-select:focus {
  border-color: var(--bs-primary);
  box-shadow: none;
}

.form-floating.input_textarea label {
  min-width: 90%;
}

.form-floating.input_textarea label::before {
  content: "";
  position: absolute;
  top: 0.9em;
  z-index: -1;
  width: 100%;
  height: 1.2em;
  background-color: white;
  box-shadow: 0 0 8px 4px #ffffff;
}

.form-floating.input_textarea > .form-control:focus ~ label, .form-floating.input_textarea > .form-control:not(:placeholder-shown) ~ label, .form-floating.input_textarea > .form-select ~ label {
  opacity: 0.95;
  color: gray;
}

.tablesorter {
  /*thead .tablesorter-header {
      background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
      background-position: center right;
      background-repeat: no-repeat;
      cursor: pointer;
      white-space: normal;
      padding: 4px 20px 4px 4px;
  }
  thead .tablesorter-headerAsc {
      background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
  }
  thead .tablesorter-headerDesc {
      background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
  }*/
}
.tablesorter .sorter-false {
  background-image: none;
  cursor: default;
  padding: 4px;
}
.tablesorter .disabled {
  display: none;
}

table#request-history-table {
  font-size: 0.8rem;
}

.select2-results__option {
  font-size: 0.8rem;
}

.youtube {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* Verhältnis von Breite zu Höhe des Videos (9:16=56.25%) */
}

.youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.paypal-button + .paypal-button-placeholder {
  background-color: #ffc439;
  min-height: 35px;
  border-radius: 4px;
  margin-bottom: 6px;
  padding: 5px;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff !important;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-size: 0.6rem;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

/*
IE11 fixes
==================================================
Contains specific browser fixes which are only applied in IE.
*/
html.is-ie .input-group-append .icon {
  display: block;
}
html.is-ie .input-group-append svg {
  top: 0;
  vertical-align: auto;
}
html.is-ie .cart-item-row .cart-item-quantity {
  z-index: 1;
}
html.is-ie .product-image-wrapper {
  flex-basis: auto;
}
html.is-ie .product-image-link {
  display: block;
}
html.is-ie .product-image {
  margin: auto;
}

body {
  font-family: "Quicksand", sans-serif;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

strong, b {
  font-weight: 500;
}

.h6,
h6 {
  font-size: 1em;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 500 !important;
  font-family: "Omnes", sans-serif;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5, h6 {
  font-size: 1.2rem;
}

@font-face {
  font-family: "Omnes";
  src: url("/assets/styles/fonts/omnes/Omnes-Bold.woff2") format("woff2"), url("/assets/styles/fonts/omnes/Omnes-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Omnes";
  src: url("/assets/styles/fonts/omnes/Omnes-Medium.woff2") format("woff2"), url("/assets/styles/fonts/omnes/Omnes-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Quicksand";
  src: url("/assets/styles/fonts/quicksand/Quicksand-Light.woff2") format("woff2"), url("/assets/styles/fonts/quicksand/Quicksand-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Quicksand";
  src: url("/assets/styles/fonts/quicksand/Quicksand-Bold.woff2") format("woff2"), url("/assets/styles/fonts/quicksand/Quicksand-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Quicksand";
  src: url("/assets/styles/fonts/quicksand/Quicksand-Medium.woff2") format("woff2"), url("/assets/styles/fonts/quicksand/Quicksand-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Quicksand";
  src: url("/assets/styles/fonts/quicksand/Quicksand-Regular.woff2") format("woff2"), url("/assets/styles/fonts/quicksand/Quicksand-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Quicksand";
  src: url("/assets/styles/fonts/quicksand/Quicksand-SemiBold.woff2") format("woff2"), url("/assets/styles/fonts/quicksand/Quicksand-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
.required::after {
  content: " *";
  color: var(--bs-danger);
}

.grecaptcha-badge {
  visibility: hidden;
}

.prio-badge {
  font-size: 0.75rem;
  right: -50px !important;
  top: -15% !important;
  left: unset !important;
}

.tooltip-inner {
  text-align: left;
  background-color: var(--bs-primary);
  max-width: 320px;
}
@media (min-width: 992px) {
  .tooltip-inner {
    max-width: 450px;
  }
}

.card {
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}

.form-floating label {
  color: black;
  font-size: 1rem;
  font-weight: bold;
}
.form-floating .audio-record {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.25rem;
}

.form-control {
  border: solid 2px grey;
}
.form-control:focus {
  border: solid 2px var(--bs-primary);
  box-shadow: none;
}

.textarea {
  border: solid 1px black;
  border-radius: 3px;
  padding: 1rem !important;
}
.textarea pre {
  margin-bottom: 0;
}

.form-floating.input_textarea label:before {
  background: none;
  box-shadow: none;
}

.form-select:disabled {
  color: lightgrey;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px;
  background: #dfdfdf;
  border-radius: 3px;
  border: 0px solid;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 1px;
  border: 0px solid;
  height: 17px;
  width: 17px;
  border-radius: 3px;
  background: var(--bs-primary);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #dfdfdf;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px;
  background: #dfdfdf;
  border-radius: 3px;
  border: 0px solid;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 1px;
  border: 0px solid;
  height: 17px;
  width: 17px;
  border-radius: 3px;
  background: var(--bs-primary);
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #dfdfdf;
  border: 0px solid;
  border-radius: 6px;
  box-shadow: 0px 0px 0px;
}

input[type=range]::-ms-fill-upper {
  background: #dfdfdf;
  border: 0px solid;
  border-radius: 6px;
  box-shadow: 0px 0px 0px;
}

input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 1px;
  border: 0px solid;
  height: 17px;
  width: 17px;
  border-radius: 3px;
  background: var(--bs-primary);
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #dfdfdf;
}

input[type=range]:focus::-ms-fill-upper {
  background: #dfdfdf;
}

/* error message formatting */
.alert-danger ul {
  margin-bottom: 0;
}

#api-message-modal .modal-content::before {
  background-image: url("https://hosono.ai/images/logo/hosonoai-maskottchen.svg");
  background-repeat: no-repeat;
  content: "";
  height: 3rem;
  position: absolute;
  left: 1rem;
  top: -2.7rem;
  width: 3rem;
  z-index: -1;
}

.help-text {
  font-size: 0.7rem;
  color: grey;
  word-break: break-all;
  display: none;
}

.nav-tabs .nav-link {
  z-index: 1;
}
.nav-tabs .nav-link.active {
  background-color: white !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-primary) !important;
}
.nav-tabs .tab-content {
  z-index: 1;
}
.nav-tabs li.nav-item:last-child::after {
  background-image: url("https://hosono.ai/images/logo/hosonoai-maskottchen.svg");
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  right: -2rem;
  top: 0;
  height: 3rem;
  width: 3rem;
  transform: rotate(24deg);
  z-index: 0;
}

table td, table th {
  word-break: break-word;
}

.btn:hover .fa-plus {
  transition: var(--translate-smooth);
  transform: rotate(90deg) scale(1.3);
}
.btn.disabled {
  border: none;
}
.btn .fa-plus {
  transition: var(--translate-smooth);
  transform: rotate(0deg) scale(1);
}

a.btn:hover {
  text-decoration: none;
}

ul {
  list-style-position: inside;
}

#countdown {
  font-size: 1.25rem;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
.feedback-btn {
  position: fixed;
  top: 50%;
  left: calc(100% - 75px);
  transform: rotate(-90deg);
  height: auto;
  width: auto;
  z-index: 10;
  white-space: nowrap;
  display: inline-block;
}

body {
  background: white;
}

footer .nav-link,
footer a {
  --bs-nav-link-color: white;
  --bs-nav-link-hover-color: white;
  color: white;
}
footer .nav-link:hover,
footer a:hover {
  color: white;
}

nav.navbar {
  --bs-navbar-color: black;
  box-shadow: var(--box-shadow);
  font-size: 0.8rem;
}
nav.navbar .navbar-brand:hover {
  text-decoration: none;
}
nav.navbar .navbar-brand .icon {
  display: inline-block;
}
nav.navbar .nav-link {
  font-size: 1.25rem;
}
@media (min-width: 992px) {
  nav.navbar .nav-link {
    font-size: 0.9rem;
  }
}
nav.navbar .dropdown-menu ul {
  font-size: 1.25rem;
}
@media (min-width: 992px) {
  nav.navbar .dropdown-menu ul {
    font-size: 0.9rem;
  }
}

.dropdown-hover:hover > .dropdown-menu {
  display: inline-block;
}

.dropdown-hover > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

body, html {
  overflow-x: hidden;
}

body main .c-details span {
  font-weight: 300;
  font-size: 13px;
}
body main .icon {
  width: 2rem;
  height: 2rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}
body main .badge span {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
body main .progress {
  height: 10px;
  border-radius: 10px;
}
body main .text1 {
  font-size: 14px;
  font-weight: 600;
}
body main .text2 {
  color: #a5aec0;
}
body main .tool-cards {
  transform: scale(1);
  transition: all 300ms ease-in-out 0s;
}
body main .tool-cards:hover {
  transform: scale(1.05);
  transition: all 300ms ease-in-out 0s;
}
body main .tool-cards small {
  min-height: 36px;
}

#tool-headline .mascot {
  width: 5rem;
  height: 5rem;
}