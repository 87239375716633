.tablesorter {
    /*thead .tablesorter-header {
        background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
        background-position: center right;
        background-repeat: no-repeat;
        cursor: pointer;
        white-space: normal;
        padding: 4px 20px 4px 4px;
    }
    thead .tablesorter-headerAsc {
        background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
    }
    thead .tablesorter-headerDesc {
        background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
    }*/
    .sorter-false {
        background-image: none;
        cursor: default;
        padding: 4px;
    }
    .disabled {
        display: none;
    }
}

table#request-history-table {
    font-size: .8rem;
}